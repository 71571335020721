import React from 'react';
import { useEffect, useState } from 'react';
import mobMainImg from '../../assets/images/pic.jpg';
import mainImg from '../../assets/images/Skydance_Bridge.png';
import mobText from '../../assets/images/we-do-not-txt.png';
import mobImage from '../../assets/images/phone2.jpg'
import './aboutus.css'
// import { sanFrancisco } from './images';
const AboutUS = () => {
    const [width, setWidth] = useState(window.innerWidth);


    useEffect(() => {
        window.addEventListener('resize', (e) => {
            setWidth(e.target.innerWidth)
        });

        return () => {
            window.removeEventListener('resize', (e) => {
                setWidth(e.target.innerWidth)
            });
        }
    }, [])
    return (
        <>
            {
             width > 100 ? (<div className='relative'>
                    <img src={mainImg} alt="onThaGo" className="min-img" />
                    <div className="bottom-right">
                        {/* <h2 className='main-title'  >WE DO NOT <span className='stay'>Stay</span>  IN  LINE .</h2> */}
                        <img src={mobText} className="we-stay-txt" alt="" />
                        <p className='para-txt'>
                            We're restless, impatient, and driven.
                        </p>
                        <p  className='para-txt'>
                            We never wait for things to come to us.
                        </p>
                    </div>
                </div>) : width > 100 ?
                    (<div className='relative'>  
                        <img src={mobMainImg} alt="onThaGo" className="man-img" />
                        <div className="bottom-right">
                            {/* <img src={mobText} alt = "dontStayInLine"  className='mob'/> */}
                            {/* <h2 className='main-title'  >WE DO NOT <span className='stay'>Stay</span>  IN  LINE .</h2> */}
                            <img src={mobText} alt="" />
                            <p style={{ fontSize: '1rem' }}>
                                We're restless, impatient, and driven.
                            </p>
                            <p style={{ fontSize: '1rem' }}>
                                We never wait for things to come to us.
                            </p>
                        </div>
                    </div>)  :
        (  <div className='mob relative' >
          <img src={mobImage} alt="onThaGo" className="man-img" />
          <div className="bottom-right">
              {/* <img src={mobText} alt = "dontStayInLine"  className='mob'/> */}
              {/* <h2 className='main-title'  >WE DO NOT <span className='stay'>Stay</span>  IN  LINE .</h2> */}
              <img src={mobText} alt="" />
              <p style={{ fontSize: '1rem' }}>
                  We're restless, impatient, and driven.
              </p>
              <p style={{ fontSize: '1rem' }}>
                  We never wait for things to come to us.
              </p>
          </div>
      </div>    )
             
                    }
                    
                                          
                    
                   

            <div className='body'>
                <div>
                    <p 
                    >
                       People waste too much time waiting, worrying, and wondering what could have been. We think there's a better way.
                       
                    </p>

                    <p  
                    
                    > We believe in a world where technology removes the hassles and stress of everyday transactions—so humans can focus on more important things. You know, human things.
                      
                    </p>

                    <p  
                    >
                        We are fun, nerdy, exciting people who are hungry for a world without waiting.
                    
                    </p>

                </div>
                {/* <h1>Meet The Team</h1>
                <p style={{ textAlign: 'start' }}>San Francisco</p>
                <div className="row">
                    <div className="column">
                        {sanFrancisco.map((img, index) => {
                            return <img alt="" src={img} key={index} />
                        })}

                    </div>
                    <div className="column">
                        <img alt="" src="../assets/images/team/chad-lato.jpeg" />
                        <img alt="" src="../assets/images/team/chad-lato.jpeg" />
                        <img alt="" src="../assets/images/team/chad-lato.jpeg" />
                        <img alt="" src="../assets/images/team/chad-lato.jpeg" />
                        <img alt="" src="../assets/images/team/chad-lato.jpeg" />
                        <img alt="" src="../assets/images/team/chad-lato.jpeg" />
                    </div>
                    <div className="column">
                        <img alt="" src="../assets/images/team/chad-lato.jpeg" />
                        <img alt="" src="../assets/images/team/chad-lato.jpeg" />
                        <img alt="" src="../assets/images/team/chad-lato.jpeg" />
                        <img alt="" src="../assets/images/team/chad-lato.jpeg" />
                        <img alt="" src="../assets/images/team/chad-lato.jpeg" />
                        <img alt="" src="../assets/images/team/chad-lato.jpeg" />
                        <img alt="" src="../assets/images/team/chad-lato.jpeg" />
                    </div>
                    <div className="column">
                        <img alt="" src="../assets/images/team/chad-lato.jpeg" />
                        <img alt="" src="../assets/images/team/chad-lato.jpeg" />
                        <img alt="" src="../assets/images/team/chad-lato.jpeg" />
                        <img alt="" src="../assets/images/team/chad-lato.jpeg" />
                        <img alt="" src="../assets/images/team/chad-lato.jpeg" />
                        <img alt="" src="../assets/images/team/chad-lato.jpeg" />
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default AboutUS;