import './App.css';
import LandingPage from './components/LandingPage.jsx'
import {
  Routes,
  Route
} from "react-router-dom";
import Faqs from './components/Faqs/faqs';
import Header from './components/CommonComponents/Headers';
import Footer from './components/CommonComponents/Footer';
import AboutUS from './components/AboutUS/AboutUs';
import Careers from './components/Careers/Careers';
import Join from './components/joinCampus/joinCampusPage';
import RedirectComponent from './components/RedirectComponent/redirectPage';
const App = () => {
  return (
    <div className="App">
      <Header />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="faqs" element={<Faqs />} />
          <Route path="about-us" element={<AboutUS />} />
          <Route path="career" element={<Careers />} />
          <Route path="download" element={<RedirectComponent />} />
          <Route path="join-as-campus" element = {<Join/>}/>
          <Route path="**" element={<LandingPage />} />
        </Routes>
        
      <Footer />
    </div>
  );
}

export default App;
