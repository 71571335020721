import React from "react";
import mainImg from '../../assets/images/main-img.png';
import './faqs.css'
import Accordion from 'react-bootstrap/Accordion';
import { useEffect , useState} from 'react';
import { useLocation } from 'react-router-dom';
const Faqs = () => {
    const location = useLocation();
    const [activeKey, setActiveKey] = useState(null);
    var accordionItemId
  
    useEffect(() => {
      const urlParams = new URLSearchParams(location.search);
     accordionItemId = urlParams.get('id');
  
      if (accordionItemId) {
        setActiveKey(accordionItemId);
        const accordionItem = document.getElementById(accordionItemId);
        if (accordionItem) {
          accordionItem.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location.search]);
    return (
        <>
            <div >
                <img src={mainImg} alt="onThaGo" className="min-img-faqs" />
                <div className="centered-faqs">
                    <h2 className='main-img-title-faqs'  >HELP</h2>
                    <p className='sub-title-faqs'>We're here for you</p>
                    {/* <h2 className='main-img-title'  >HELP</h2> */}
                </div>
            </div>


            <h1 className="heading">Frequently Asked Questions</h1>

            <div className="paragraph">
                <p>
                    Need help? Have a recommendation? Something wrong?
                </p>
                <p>
                    Almost every question you may be have should be answered in the frequently asked questions (FAQ) below. Please check the FAQ to see if your problem can be resolved with a quick fix.
                </p>
                <p>
                    If necessary, you can also get in touch using the form below
                </p>
            </div>

            <div className="faqs-main-div">
                <div style={{ marginBottom: '1rem' }}>
                    <h3 className="text-start">General account questions</h3>
                </div>
                <div>

                    <Accordion defaultActiveKey={['0']} alwaysOpen >

                        <Accordion.Item eventKey="0">
                            <Accordion.Header>My order failed. Will I be charged?</Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    We're sorry your order didn't work out. Don't worry; your account will not be charged. Occasionally, the charge will remain as pending on your account and in around 3-5 business days it will disappear. Thanks for your patience.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Can I cancel my order?</Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    Pickup - Sorry, all pickup orders are final. The merchant receives your order immediately and begins making the food.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Where's my refund?</Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    It usually takes 3-5 business days for any refund we issued to hit your account. Unfortunately, we do not control this and it’s dependent on your financial institution. Thanks for being patient!
                                </p>

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>I was charged twice. Why?!</Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    We are sorry to hear that. We know that’s extremely frustrating. If the correct balance doesn’t show up on your account in 3 to 5 business days, please use the contact form on this page and we’ll address it ASAP
                                </p>

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>My account is locked.&nbsp; :( </Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    Ugh, I hate when that happens. Your account may be locked for suspected fraudulent activity or multiple users sharing a card.
                                </p>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>I can't access my account! </Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                No problem! Please try resetting your password. On the sign-in page, tap on “Forgot Password?”
                                </p>

                            </Accordion.Body>
                        </Accordion.Item>


                    </Accordion>

                </div>
            </div>

            <div className="faqs-main-div">
                <div style={{ marginBottom: '1rem' }}>
                    <h3 className="text-start">Order changes/cancellations</h3>
                </div>
                <div>

                    <Accordion defaultActiveKey={['0']} alwaysOpen>

                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Can I get an update on my order status? </Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    Pickup - Check the Order Status screen in the app.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>My order is delayed. What do I do?</Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    We are sorry to hear that your order is delayed.
                                </p>
                                <p className="text-start">
                                    Pickup orders – Please contact the merchant directly for a status update.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>I want to change an order after I've submitted it.</Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    Pickup – Orders for pickup are passed on to the merchant immediately. It’s best to contact the merchant directly to request modifications. Depending on whether they have begun to make your food, they may or may not be able to accommodate your request. We cannot make any payment changes
                                </p>

                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>

                </div>
            </div>

            <div className="faqs-main-div">
                <div style={{ marginBottom: '1rem' }}>
                    <h3 className="text-start">Other order questions</h3>
                </div>
                <div>

                    <Accordion defaultActiveKey={['3']} alwaysOpen >

                        <Accordion.Item eventKey="0">
                            <Accordion.Header>I made an order but it was cancelled. WHY? Will I be charged ? </Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    Unfortunately, on rare occasions no courier is available to take your order. We do our best to ensure that all orders can be processed, but if we can’t find a courier for you, we will cancel your order and ensure that you get your money back. We apologize for the inconvenience.
                                </p>
                                <p className="text-start">
                                    The reversal of the charges is done automatically. There's no need to contact us – the refund will be reflected in your account within 3-5 business days.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>The app isn’t able to “find my campus” and “locate me</Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    Please make sure you are within campus. Our system is only able to locate you when your address is within campus. Out of campus addresses are not recognized by our system.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Can I get a refund on my order?</Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    We’re sorry your order didn’t work out! Please contact us using the side panel of your app and give the details of your order:
                                </p>

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3" id="final-orders" active={accordionItemId === 'final-orders'}>
                            <Accordion.Header>Are all orders final?</Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                Yes, all orders are final. Reason being the restaurant has already charged your card and already started preparing your order the moment they accept it and by the time you send in a change or an edit for your order via in-app chat, your order is almost always done. This will force the restaurant to discard your whole order and start a brand new order which means losses for the restaurant and affecting their bottom line and if this is repeated with many customers over time, the restaurant will be facing going out of business.
                                </p>

                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>

                </div>
            </div>

            <div className="faqs-main-div">
                <div style={{ marginBottom: '1rem' }}>
                    <h3 className="text-start">Fees</h3>
                </div>
                <div>

                    <Accordion>

                        <Accordion.Item eventKey="0">
                            <Accordion.Header>I notice that I'm paying a fee for pickup. Why do I have to pay this fee ? </Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    We’ve had great positive feedback from students and staff regarding the value Onthago provides. We are grateful and excited by this reception, and we’re very pleased to be making life on campus better. With that said, setting up Onthago on a campus requires dedicated hardware, software, and expertise. It also requires the hard work of people making sure that everything goes smoothly. Because we earn no revenue from food sales, we need to charge for our service in order to keep operating.
                                </p>
                                <p className="text-start">
                                    Because you are the customer, <b> your voice is the most important to us</b>. We are always delighted to hear from you and to take feedback on board.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>How do pickup fees work ?</Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    There’s a lot that goes on behind the scenes to bring you a seamless system, and the cost of using Onthago will now be an extension of your meal plan. Students with the meal plan can simply charge pickup fees to their flex plan/declining balance (without any extra work). For students without a meal plan, the charges will be applied to your credit card (or you could look at one of the University’s meal plans for additional savings on each order).
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                </div>
            </div>

            {/* <div className="faqs-main-div">
                <div style={{ marginBottom: '1rem' }}>
                    <h3 className="text-start">Promos & discounts</h3>
                </div>
                <div>

                    <Accordion>

                        <Accordion.Item eventKey="0">
                            <Accordion.Header>I didn't receive a discount or credit that I believe I'm entitled to. </Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    You may have missed a discount/promo for a few reasons:
                                </p>
                                <p className="text-start">
                                    Many of our promos are limited in quantity and time. We're sorry if you missed this current promo – do check back as we often offer some exciting promotions. But act fast.
                                </p>
                                <p className="text-start">
                                    Some promos are location specific. You may have just been out of the range of the promo.
                                </p>
                                <p className="text-start">
                                    If you are sure that a discount applies to you and it did not appear at checkout, we are really sorry to hear that! Please contact us using the form on this page and we’ll try our best to resolve this for you. Do include your order number and date of order. It’ll help us get you your discount faster.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>How do I redeem a promo code ?</Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    Tap on the navigation menu on the top left corner of any screen on the app. Select “Promo Code” from the side bar. You’ll see the discount applied at checkout.
                                </p>
                                <p className="text-start">
                                    Please note that several discounts do not require a promo code – they will automatically be applied to eligible orders
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                </div>
            </div> */}

            <div className="faqs-main-div">
                <div style={{ marginBottom: '1rem' }}>
                    <h3 className="text-start">Working for Onthago</h3>
                </div>

                <div>

                    <Accordion >

                        <Accordion.Item eventKey="0">
                            <Accordion.Header>I love Onthago. How do I work for you ? </Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    We are always looking to hire passionate and talented individuals for our Onthago team. Please check our <a href="/career" target="" rel="noopener noreferrer" className="career-link"> Jobs</a>  page for more information.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>I've applied for a job! What next ?</Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    Thanks for your application! Our team of equally talented and passionate people are reviewing your application. Please be patient with us as we want to make sure we give everyone an equal opportunity and amount of time.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>


                    </Accordion>

                </div>
            </div>

        </>
    )
}

export default Faqs