
import { useEffect, useState } from 'react';
import navIcon from '../../assets/images/navIcon.png';
import Button from 'react-bootstrap/Button';
import { useNavigate,useLocation } from "react-router-dom";

const Header = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [isHomePage, setIsHomePage]= useState(false)
    let navigate = useNavigate();
    const location= useLocation();
    useEffect(() => {
        window.addEventListener('resize', (e) => {
            setWidth(e.target.innerWidth)
        });
        if(location.pathname==='/'){
            setIsHomePage(true)
        }else{
            setIsHomePage(false)
        }
        return () => {
            window.removeEventListener('resize', (e) => {
                setWidth(e.target.innerWidth)
            });
        }
    }, [location.pathname]);
    const isRouteInclude=(routName)=>{
        let a= location.pathname.includes(routName);
        return location.pathname.includes(routName);
    }

    const navigatetoHome = () => {
        navigate('/');
    }

    const navigateToAbout = () => {
        navigate('about-us');
    }

    return (
        <>
            {width > 768 ?
                <div className="nav" >
                    <img style={{ cursor: 'pointer' }} src={navIcon} alt="onthago" height="72" width="206" onClick={navigatetoHome} />
                    <span >
                        {/* <Button variant="outline-danger">STAY TUNED</Button>{' '} */}
                      {isHomePage ===false?  <Button variant="danger" onClick={navigatetoHome}>HOME</Button>:
                      <>
                        <Button variant="danger" onClick={navigateToAbout}>ABOUT US</Button>
                        </>}
                    </span>
                </div> :
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0 1rem' }} >
                    <img src={navIcon} alt="" style={{
                        height: '51px',
                        width: '113px',
                        objectFit: 'contain',
                    }} onClick={navigatetoHome} />
                    <span >
                        {/* <Button variant="outline-danger">STAY TUNED</Button>{' '} */}
                       {isHomePage==false ? <Button variant="danger" style={{
                            fontSize: '12px',
                        }}  onClick={navigatetoHome}>HOME</Button>:
                       <>
                        {' '}
                      <Button variant="danger" style={{
                            fontSize: '12px',
                        }} onClick={navigateToAbout}>ABOUT US  </Button></>}
                        {' '}
                    </span>
                </div>
            }
        </>
    )

}

export default Header