import { useEffect, useState } from 'react';
import footerImg from '../../assets/images/footerIcon.png';
import circle from '../../assets/images/circle.svg';
import instagram from '../../assets/images/Twitter.png'
import linkedin from '../../assets/images/linkedin.svg';
import facebook from '../../assets/images/facebook.svg';
import twitter from '../../assets/images/twitter.svg';
import tiktok from '../../assets/images/tik.svg';
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const [width, setWidth] = useState(window.innerWidth);
    let navigate = useNavigate();

    const topFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    useEffect(() => {
        window.addEventListener('resize', (e) => {
            setWidth(e.target.innerWidth)
        });

        return () => {
            window.removeEventListener('resize', (e) => {
                setWidth(e.target.innerWidth)
            });
        }
    }, []);

    const navigateToFaqs = () => {
        topFunction();
        navigate('faqs');
    }

    const navigateToCampus = () => {
        topFunction();
        navigate('join-as-campus');
    }

    return (
        <>
            {width > 768 ?
                <div style={{ background: "#D8483D" }}>
                    <div>
                        <img src={circle} alt="" style={{
                            position: 'relative',
                            bottom: '34px',
                            cursor: 'pointer'
                        }} onClick={() => topFunction()} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column' }}>
                        <img src={footerImg} style={{
                            height: '88px',
                            width: '50%',
                            objectFit: 'contain'
                        }} alt="" />
                        <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1.3rem' }}>
                            <h6 style={{ color: 'white', marginRight: '1rem',cursor: 'pointer' }} onClick={navigateToCampus}>Join As A Campus </h6>
                            <h6 style={{ color: 'white', marginRight: '1rem', cursor: 'pointer' }} onClick={navigateToFaqs} >Help</h6>
                        </span>
                        <span>
                            <a href="https://twitter.com/OnthagoApp" target="_blank" rel="noopener noreferrer"> <img src={instagram} alt="" className='mr-1' style={{ position: 'relative', bottom: '1px', width: "30px" }} /> </a>
                            <a href="https://www.linkedin.com/company/onthagoapp" target="_blank" rel="noopener noreferrer"> <img src={linkedin} alt="" className='mr-1' style={{ position: 'relative', bottom: '3px' }} /> </a>
                            <a href="https://www.facebook.com/profile.php?id=100089806482327" target="_blank" rel="noopener noreferrer"> <img src={facebook} alt="" className='mr-1' /> </a>
                            <a href="https://www.instagram.com/onthago.app" target="_blank" rel="noopener noreferrer"><img src={twitter} alt="" className='mr-1' /> </a>
                            <a href="https://www.tiktok.com/@onthagoapp?lang=en" target="_blank" rel="noopener noreferrer"> <img src={tiktok} alt="" className='mr-1' /> </a>
                        </span>
                        <p className='footer-address'>© Onthago,Inc. / 3641 24th Ave SE</p>
                        <p className='footer-address mt-0'>Norman, OK 73072</p>
                        <hr />

                        <p style={{ color: 'white' }}>
                            <a href="https://www.freeprivacypolicy.com/live/148ee08f-9f96-448b-95a9-4e433ad12c03" style={{ textDecoration: 'none', color: 'white' }}>Privacy Policy</a> |
                            <a href="https://www.freeprivacypolicy.com/live/148ee08f-9f96-448b-95a9-4e433ad12c03" style={{ textDecoration: 'none', color: 'white' }}> Terms of Use</a>
                        </p>
                    </div>
                </div> :

                <div style={{ background: "#D8483D", marginTop: '3rem' }}>
                    <div>
                        <img src={circle} alt="" style={{
                            position: 'relative',
                            bottom: '34px',
                            cursor: 'pointer',
                        }} onClick={() => topFunction()} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <img src={footerImg} alt="" style={{
                            height: '50%',
                            width: '50%',
                            objectFit: 'contain'
                        }} />
                        <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1.3rem' }}>
                            <h6 style={{ color: 'white', marginRight: '1rem' }} onClick={navigateToCampus}>Join As A Campus</h6>
                            <h6 style={{ color: 'white', marginRight: '1rem' }} onClick={navigateToFaqs}>Help</h6>
                        </span>
                        <span>
                            <a href="https://twitter.com/OnthagoApp" target="_blank" rel="noopener noreferrer"> <img src={instagram} alt="" className='mr-1' style={{ position: 'relative', bottom: '1px', width: "30px" }} /> </a>
                            <a href="https://www.linkedin.com/company/onthagoapp" target="_blank" rel="noopener noreferrer"> <img src={linkedin} alt="" className='mr-1' style={{ position: 'relative', bottom: '3px' }} /> </a>
                            <a href="https://www.facebook.com/profile.php?id=100089806482327" target="_blank" rel="noopener noreferrer"> <img src={facebook} alt="" className='mr-1' /> </a>
                            <a href="https://www.instagram.com/onthago.app" target="_blank" rel="noopener noreferrer"><img src={twitter} alt="" className='mr-1' /></a>
                            <a href="https://www.tiktok.com/@onthagoapp?lang=en" target="_blank" rel="noopener noreferrer"> <img src={tiktok} alt="" className='mr-1' /></a>
                        </span>
                        <p className='footer-address' style={{fontSize:'14px'}}>© Onthago,Inc. / 3641 24th Ave SE Norman, OK 73072 </p> 
                        {/* <p className='footer-address mt-0'></p> */}
                        <hr />
                        <p style={{ color: 'white' }}>
                            <a href="https://www.freeprivacypolicy.com/live/148ee08f-9f96-448b-95a9-4e433ad12c03" target="_blank" rel="noopener noreferrer">Privacy Policy</a> |
                            <a href="https://www.freeprivacypolicy.com/live/148ee08f-9f96-448b-95a9-4e433ad12c03" target="_blank" rel="noopener noreferrer"> Terms of Use</a>
                        </p>
                    </div>
                </div>
            }
        </>
    )
}

export default Footer