import  { useEffect } from 'react';

function RedirectComponent() {
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.indexOf('android') > -1) {
      window.location.href = 'https://play.google.com/store/apps/details?id=co.tierzen.onthago';
    } else if (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipad') > -1) {
      window.location.href = 'https://apps.apple.com/pk/app/onthago/id6449786148';
    } else {
      // Provide a fallback link for other platforms or browsers
      window.location.href = 'https://onthago.app/';
    }
  }, []); // Empty dependency array ensures the effect runs only once

  return null; // This component doesn't render anything
}

export default RedirectComponent;

