

import React, { useState } from 'react';
import mainImg from '../../assets/images/office_image.jpg';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import './Careers.css';
import axios from 'axios';

const Careers = () => {
    const [show, setShow] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Something went wrong please check your internet connection or try again');
    const timezone= (Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [formData, setFormData] = useState({
        first_name:'',
        last_name:'',
        campus_name:'',
        email:'',
        phone_no:'',
        reason:'',
        type:0,
        version_number:1.0,
        timezone
    });
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleChange =
    (prop) => (event) => {
      setFormData({ ...formData, [prop]: event.target.value });
    };
    const clearForm=()=>{
        setFormData(
            {first_name:'',
        last_name:'',
        campus_name:'',
        email:'',
        phone_no:'',
        reason:'',
        type:0,
        version_number:1.0,
        timezone}
        )
    }

    const submitForm=async ()=>{
        try {

            setIsError(false)
            let response= await axios.post('https://api.onthago.app/client/apply_job',formData)
            if(response.data.success==true && response.status==200){
                
                handleClose();
                clearForm();
            }else if(response.data.success==false){
                setIsError(true)
                setErrorMessage(response.data.message)
            }
            else{
                setIsError(true)
            }
        } catch (error) {
            console.log(error)
        }
    }
    
    return (
        <>
            <div className='relative'>
                <img src={mainImg} alt="onThaGo" className="job-img" />
                <div className="centered-text">
                    <h2 className='main-team-title'  >JOIN OUR TEAM</h2>
                    <p className='sub-team-title'>
                        Hate waiting? So do we. Let's talk.
                    </p>
                </div>
            </div>

            <div className='body'>
                <div>
                    <p 
                    // className='text-size'
                    >
                        Onthago is on an incredible growth trajectory, and we're excited to collaborate with wonderful colleagues to achieve our goals.</p>
                    <p 
                    // className='text-size'
                    >
                        If you love working with smart, amazing people who share the belief that technology should eliminate wasted time and stress, then Onthago might be the perfect place for you.
                    </p>
                    <p>
                        <h3> Current jobs: </h3>
                        <b className='jobs' onClick={handleShow}>
                            Campus ambassador
                        </b>
                    </p>

                </div>
            </div>

            <Modal show={show} onHide={handleClose} backdrop="static"
                keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Join as a Ambassador</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e)=>{
                        e.preventDefault();
                        submitForm();
                    }}>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="firstName">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control 
                                        type="input" placeholder="Enter First Name" autoFocus 
                                        onChange={handleChange('first_name')}
                                        required />

                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="lastName">
                                        <Form.Label>Second name</Form.Label>
                                        <Form.Control type="input" placeholder="Enter Second Name" 
                                        onChange={handleChange('last_name')}
                                        required/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="Yourcampus">
                                        <Form.Label>Your campus</Form.Label>
                                        <Form.Control type="input" placeholder="Enter Campus Name"
                                        onChange={handleChange('campus_name')}
                                        required/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" placeholder="Enter .edu Email"
                                            onChange={handleChange('email')}
                                        required/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="PhoneNumber">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control type="tel" placeholder="Enter Your Phone Number" 
                                        onChange={handleChange('phone_no')}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="reason"
                                    >
                                        <Form.Label>Why do you wanna be a our campus ambassador?</Form.Label>
                                        <Form.Control as="textarea" rows={3} 
                                        onChange={handleChange('reason')}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                           {isError==true? <p style={{color:'red'}}>{errorMessage}</p>:''}
                        </Container>
                        <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" type='submit' >
                        Apply
                    </Button>
                </Modal.Footer>
                    </Form>
                </Modal.Body>
               
            </Modal>
        </>
    )
}

export default Careers;