import { useEffect, useState } from 'react';
import mainImg from '../assets/images/banner.jpg';
import scrollImg from '../assets/images/scroll-img.jpg';
import phoneImg from '../assets/images/mobile-frame.png'
import downloadBtn from '../assets/images/download-btn.svg';
import btn from '../assets/images/btn.svg';
// import qrCode from '../assets/images/qrCode.png';
import LandingPageMob from './LandingPageMob';
import './LandingPage.css';
import QRCode from 'qrcode.react';
import { Link } from 'react-router-dom';

const LandingPage = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const link = 'https://apps.apple.com/pk/app/onthago/id6449786148'; 
  

    useEffect(() => {
        window.addEventListener('resize', (e) => {
            setWidth(e.target.innerWidth)
        });

        return () => {
            window.removeEventListener('resize', (e) => {
                setWidth(e.target.innerWidth)
            });
        }
    }, [])


    return (
        <>
            {width > 768 ?
                <div>
                   

                    <div className='landing-page'>
                        <img src={mainImg} alt="onThaGo" className="min-img" />
                        <div className="centered">
                            {/* <h2 className='main-img-title'  ><span>Skip</span> THE LINE</h2> */}
                           <a href={link} target='blank'> <img src={btn} alt="" width="235" style={{ cursor: 'pointer' }} /> </a>
                        </div>
                    </div>

                    {/* hungry section */}
                    <div style={{
                        backgroundImage: `url("${scrollImg}")`
                    }} className="backGroundImg">
                        <div className="hungry-section" >
                            <h1 >Hungry?</h1>
                            <h5 style={{ fontSize: '34px', letterSpacing: '-1px' }}>With No Time To Stay In Line </h5>
                            <h5 style={{ fontSize: '34px', lineHeight: '1', letterSpacing: '-1px' }}><b>Onthago</b> Helps You Skip The Line! </h5>
                        </div>
                    </div>

                    {/* hungry section */}

                    {/* body  */}
                    <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '-9rem' }}>
                        <span style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', marginTop: '5.5rem' }}>
                            <span>

                                <h1 style={{ color: '#D8483D', textAlign: 'start', fontWeight: '700', letterSpacing: '-1px' }}>BECAUSE WE DON'T STAY IN LINE</h1>
                                <h5 style={{ textAlign: 'start', letterSpacing: '-1px', fontSize: '1.5rem', lineHeight: '1' }}>Folks linger in queues, standing, pondering and </h5>
                                <h5 style={{ textAlign: 'start', letterSpacing: '-1px', fontSize: '1.5rem', lineHeight: '1' }}>wondering of what it might have been. We believe </h5>
                                <h5 style={{ textAlign: 'start', letterSpacing: '-1px', fontSize: '1.5rem' }}>there is a faster way. </h5>
                            </span>
                            <br />

                            <span >
                                <h6 style={{ textAlign: 'start' }}>Download App</h6>
                                <img src={downloadBtn} alt="" />
                            </span>
                            <div style={{ margin: '2rem 0 12rem 0' }}>
                               <QRCode value={link} />
                             <Link to={link} target="_blank" rel="noopener noreferrer">
                                 </Link>
                            </div>
                        </span>
                        <span>
                            <img src={phoneImg} alt="phone" style={{
                                position: 'relative',
                                bottom: '6rem',
                                width:'75%'
                            }} />
                        </span>
                    </div>

                    {/* body end  */}

                    {/* footer  */}
                    
                </div> :
                <LandingPageMob></LandingPageMob>
            }
        </>
    )
}

export default LandingPage;