import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import "./joinCampusPage.css";
import mainImg from "../../assets/images/cover-image.jpg"
import React , { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import '../../components/Careers/Careers.css';
export default function JoinCampusPage() {
const [show, setShow] = useState(false);
const [isError, setIsError] = useState(false);
const [responseData, setResponseData] = useState(false);
const [errorMessage, setErrorMessage] = useState('Something went wrong please check your internet connection or try again');
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const timezone= (Intl.DateTimeFormat().resolvedOptions().timeZone);
const [formData, setFormData] = useState({
    first_name:'',
    last_name:'',
    campus_name:'',
    email:'',
    phone_number:'',
    version_number:1.0,
    timezone
});
const handleChange =
(prop) => (event) => {
  setFormData({ ...formData, [prop]: event.target.value });
};
const clearForm=()=>{
    setFormData(
        {first_name:'',
    last_name:'',
    campus_name:'',
    email:'',
    phone_number:'',
    version_number:1.0,
    timezone
  }
    )
}
const submitForm=async ()=>{
  try {

      setIsError(false)
      let response= await axios.post('https://api.onthago.app/client/request_a_campus',formData)
      if(response.data.success==true && response.status==200){
        setResponseData(response.data.success)
          clearForm();
          setTimeout(() => {
            setResponseData(false);
          }, 1000);
      }else if(response.data.success==false){
          setIsError(true)
          setErrorMessage(response.data.message)
      }
      else{
          setIsError(true)
      }
  } catch (error) {
      console.log(error)
  }
}


  

  return (
    <div>
      {/* <img src = {CoverImg} style ={{width:"100%",position:"relative"}} alt = "cover"  ></img> */}
      <div style={{position:"relative"}}>
      <div
        className=""
        style={{ }}
      >
        <img src={mainImg} alt="background" className="coverImg"></img>
        <div className="pickup-txt" style ={{position:"absolute", color:"white" , bottom:"180px" , left:"160px" }} >
          <h2 style={{textAlign:"left"}}>Express Pickup</h2>
          <p style={{textAlign:"left"}}>
            Onthago is made for college campuses express pickup.<br></br> We help you
            streamline your foodservice on Onthago app.
          </p>
        </div>
      </div>
      </div>
      <div className="container ">
        <Card style={{ width: "100%" , margin: "50px 0px"}}>
          <Card.Body >
                      <form onSubmit={(e)=>{
                        e.preventDefault();
                        submitForm();
                    }}>
          <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="firstName">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control type="text" value={formData.first_name} onChange={handleChange('first_name')} placeholder="Enter First Name" autoFocus />

                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="lastName">
                                        <Form.Label>Second name</Form.Label>
                                        <Form.Control type="input" value={formData.last_name} onChange={handleChange('last_name')} placeholder="Enter Second Name" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="Yourcampus">
                                        <Form.Label>Your campus</Form.Label>
                                        <Form.Control type="input" value={formData.campus_name}  onChange={handleChange('campus_name')} placeholder="Enter Campus Name" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" value={formData.email}  onChange={handleChange('email')} placeholder="Enter .edu Email" />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="PhoneNumber">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control type="tel" value={formData.phone_number}  onChange={handleChange('phone_number')} placeholder="Enter Your Phone Number" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {
                              isError===true?
                              <p style={{color:'red'}}>{errorMessage??'Something went wrong'}</p>:
                              ''
                            }
                            {
                              (isError===false && responseData==true)? <p style={{color:'green'}}>{'Sent Request'}</p>:''
                            }
                            
                            {/* <Row>
                                <Col>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="reason"
                                    >
                                        <Form.Label>Conatct Us</Form.Label>
                                        <Form.Control as="textarea" rows={3} />
                                    </Form.Group>
                                </Col>
                            </Row> */}
            <Button style={{   background: 'rgb(216, 72, 61)', border:'none', borderRadius:'5px'}} type='submit'>Submit</Button>
            </form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

// #endregion

